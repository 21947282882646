import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useCart } from "react-use-cart"

import InputWithHiddenLabel from "../UI/InputWithHiddenLabel"
import InputWithInlineLeading from "../UI/InputWithInlineLeading"

import ListboxSelect from "../UI/ListboxSelect"
import RadioGroupSides from "../UI/RadioGroupSides"
import Textarea from "../UI/Textarea"
import InputWrapper from "../UI/InputWrapper"
import RadioGroupCards from "../UI/RadioGroupCards"

import AddButton from "../UI/AddButton"

import {
  radioS,
  colorsOptions,
  coversData,
  colorsData,
  colors2Data,
  directionOptionsS,
  directionOptionsF,
} from "./data"

import Header from "./Header"
import Wrapper from "./Wrapper"
import Alert from "../UI/Alert"

import Notification from "../UI/Notification"

function FForm({ data }) {
  const [id, setId] = useState(uuidv4())

  const [dNum, setDNum] = useState("")

  const [a, setA] = useState("")
  const [b, setB] = useState("")
  const [h, setH] = useState("")
  const [n, setN] = useState("")

  const [cover, setCover] = useState(coversData[0])
  const [color, setColor] = useState(colorsData[0])
  const [colorID, setColorID] = useState("")
  const [color2, setColor2] = useState(colors2Data[0])
  const [color2ID, setColor2ID] = useState("")

  const [colorsNum, setColorsNum] = useState(colorsOptions[0])
  const [shDirection, setShDirecton] = useState(directionOptionsS[0])
  const [frDirection, setFrDirecton] = useState(directionOptionsF[0])

  const [frA, setFrA] = useState("")
  const [frB, setFrB] = useState("")
  const [frH, setFrH] = useState("")

  const [top, setTop] = useState(false)
  const [bottom, setBottom] = useState(false)
  const [left, setLeft] = useState(false)
  const [right, setRight] = useState(false)

  const [comment, setComment] = useState("")
  const selected = radioS[0]

  const { addItem } = useCart()

  const [isAlertVisible, setAlertVisible] = useState(false)
  const [isNotificationVisible, setNotificationVisible] = useState(false)

  const emptyForm = () => {
    setId(uuidv4())

    setDNum("")
    setA("")
    setB("")
    setH("")
    setN("")

    setCover(coversData[0])
    setColor(colorsData[0])
    setColorID("")
    setColor2(colors2Data[0])
    setColor2ID("")

    setColorsNum(colorsOptions[0])

    setShDirecton(directionOptionsS[0])
    setFrDirecton(directionOptionsF[0])

    setFrA("")
    setFrB("")
    setFrH("")

    setTop(false)
    setBottom(false)
    setLeft(false)
    setRight(false)

    setComment("")
  }
  const handleClick = () => {
    if (dNum === "" || a === "" || b === "" || h === "" || n === "") {
      setAlertVisible(true)
      setNotificationVisible(false)
    } else {
      window.scrollTo(0, 0)

      setAlertVisible(false)
      setNotificationVisible(true)

      addItem(
        {
          id,
          name: "МДФ панели с фрезеровкой",
          dNum,
          a,
          b,
          h,
          cover,
          colorsNum,
          color,
          colorID,
          color2,
          color2ID,
          selected,
          top,
          left,
          right,
          bottom,
          shDirection,
          frDirection,
          frA,
          frB,
          frH,
          comment,
          price: 1,
        },
        n
      )
      emptyForm()
    }
  }

  return (
    <div className="space-y-2">
      <Wrapper>
        <Header title="МДФ панели с фрезеровкой">
          <InputWithHiddenLabel
            value={dNum}
            setValue={setDNum}
            label="№ договора"
            type="text"
            name="numD"
            id="numD"
            placeholder="№ договора"
          />
        </Header>
        <div className="flex flex-wrap justify-between space-x-0 space-y-1 sm:justify-start md:space-x-8">
          <InputWrapper label="Ширина" className="justify-between">
            <InputWithInlineLeading
              value={a}
              setValue={setA}
              label="Ширина"
              pre="a"
              post="мм"
              name="aSize"
              id="aSize"
              max="3000"
            />
          </InputWrapper>
          <InputWrapper label="Толщина" className="justify-between">
            <InputWithInlineLeading
              value={b}
              setValue={setB}
              label="Толщина"
              pre="b"
              post="мм"
              name="bSize"
              id="bSize"
              max="600"
            />
          </InputWrapper>

          <InputWrapper label="Высота">
            <InputWithInlineLeading
              value={h}
              setValue={setH}
              label="Высота"
              pre=""
              post="мм"
              name="hSize"
              id="hSize"
              max="3000"
            />
          </InputWrapper>

          <InputWrapper label="Кол-во">
            <InputWithInlineLeading
              value={n}
              setValue={setN}
              label="Количество"
              pre=""
              post="шт"
              name="nSize"
              id="nSize"
              max="10000"
            />
          </InputWrapper>
        </div>
        <div className="grid grid-cols-1 justify-start space-x-0 border-b border-gray-300 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Покрытие" className="justify-between">
            <ListboxSelect
              data={coversData}
              value={cover}
              onChange={setCover}
            />
          </InputWrapper>

          <InputWrapper label="Кол-во цветов" className="justify-between">
            <RadioGroupCards
              options={colorsOptions}
              checkedItem={colorsNum}
              onChange={setColorsNum}
            />
          </InputWrapper>
        </div>
        <div className="grid grid-cols-1 justify-start space-x-0 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Цвет 1" className="justify-between">
            <ListboxSelect
              data={colorsData}
              value={color}
              onChange={setColor}
            />
          </InputWrapper>

          <div className="pt-1">
            <InputWithHiddenLabel
              value={colorID}
              setValue={setColorID}
              label="№ цвета"
              type="text"
              name="numС"
              id="numС"
              placeholder="№ цвета"
            />
          </div>
          {colorsNum.name === colorsOptions[1].name ? (
            <>
              <InputWrapper label="Цвет 2" className="justify-between">
                <ListboxSelect
                  data={colors2Data}
                  value={color2}
                  onChange={setColor2}
                />
              </InputWrapper>

              <div className="pt-1">
                <InputWithHiddenLabel
                  value={color2ID}
                  setValue={setColor2ID}
                  label="№ цвета"
                  type="text"
                  name="numС"
                  id="numС"
                  placeholder="№ цвета"
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="grid grid-cols-1 justify-start space-x-0 space-y-1 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Направление шпона" className="justify-between">
            <RadioGroupCards
              options={directionOptionsS}
              checkedItem={shDirection}
              onChange={setShDirecton}
            />
          </InputWrapper>

          <InputWrapper
            label="Нaправление фрезеровки"
            className="justify-between"
          >
            <RadioGroupCards
              options={directionOptionsF}
              checkedItem={frDirection}
              onChange={setFrDirecton}
            />
          </InputWrapper>
        </div>
        <div className="grid grid-cols-1 justify-start space-x-0 space-y-1 border-b border-gray-300 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Ширина паза">
            <InputWithInlineLeading
              value={frA}
              setValue={setFrA}
              label="Ширина фрезеровки"
              pre=""
              post="мм"
              name="frA"
              id="frA"
              placeholder="0"
              max="12"
            />
          </InputWrapper>

          <InputWrapper label="Глубина паза">
            <InputWithInlineLeading
              value={frB}
              setValue={setFrB}
              label="Глубина паза"
              pre=""
              post="мм"
              name="frB"
              id="frB"
              placeholder="0"
              max="10"
            />
          </InputWrapper>

          <InputWrapper label="Шаг фрезеровки">
            <InputWithInlineLeading
              value={frH}
              setValue={setFrH}
              label="Шаг фрезеровки"
              pre=""
              post="мм"
              name="frH"
              id="frH"
              placeholder="0"
              max="30"
            />
          </InputWrapper>
        </div>
        <div className="">
          <h3 className="pt-2 text-base sm:text-lg">Кромка</h3>

          <RadioGroupSides
            data={data}
            items={radioS}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            setTop={setTop}
            setLeft={setLeft}
            setRight={setRight}
            setBottom={setBottom}
          />
        </div>
        <div className="pb-4">
          <Textarea value={comment} setValue={setComment} />
        </div>
        <ul className="py-2 font-mono text-xs">
          <li>№ договора: {dNum}</li>
          <li>
            Сечение: {a} мм x {b} мм, Длина: {h} мм, Количество: {n} шт.,
            Покрытие: {cover.name}
          </li>
          <li>
            Кол-во цветов: {colorsNum.name}, Цвет 1: {color.name}, № цвета:{" "}
            {colorID}
          </li>
          <li>
            {colorsNum.id === 2 &&
              `Цвет 2: ${color2.name}, № цвета: ${color2ID}`}
          </li>
          <li>Направление шпона: {shDirection.name}</li>
          <li>
            Направление фрезеровки: {frDirection.name}, Ширина паза: {frA} мм,
            Глубина паза: {frB} мм, Шаг фрезеровки: {frH} мм
          </li>
          <li>
            Кромка: {top && "Вверх"} {left && "Слева"} {right && "Справа"}{" "}
            {bottom && "Низ"}
          </li>
          <li>Комментарии: {comment}</li>
        </ul>
      </Wrapper>

      <Alert isVisible={isAlertVisible} dNum={dNum} a={a} b={b} h={h} n={n} />
      <AddButton onClick={handleClick} />
      <Notification
        isVisible={isNotificationVisible}
        setVisible={setNotificationVisible}
      />
    </div>
  )
}

export default FForm
