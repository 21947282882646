import React from "react"
import { graphql } from "gatsby"

import FForm from "../components/Forms/FForm"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FPage = ({ data }) => (
  <Layout>
    <SEO title="МДФ панели с фрезеровкой" />

    <FForm data={data} />
  </Layout>
)

export const query = graphql`
  query SPageQuery {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 240, placeholder: BLURRED, formats: PNG)
          }
        }
      }
    }
  }
`

export default FPage
